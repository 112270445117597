/* You can add global styles to this file, and also import other style files */
// Font Awesome
@import '@fortawesome/fontawesome-free/css/all.css';

// MDB SCSS
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

@import './styles/variables.scss';


*{
  box-sizing: border-box;
}

.option-text{
    font-weight: 800;
    font-family: $primary-font;
    font-optical-sizing: auto;
    color: #001C44;
  
}

.option{
  border-radius: 15px;
}

.select-options-wrapper,
.select-dropdown{
  border-radius: 20px;
}

.select-dropdown-container{
  margin-top: 0.3rem;
}

.form-notch{
  visibility: hidden;
}



mdb-select {
  border-radius: 2em;
  box-shadow: 0px 0.24rem 0.2rem lightgrey;
  font-family: $primary-font;
  font-family: 'Montserrat';
  font-family: "Montserrat", sans-serif;



  .placeholder-active{
    font-weight: 800;
    font-family: $primary-font;
    font-optical-sizing: auto;
    color: #001C44;

  }

  background-color: white !important;

  .select-input.form-control {
    background-color: none !important;
  }

  .form-control {
    background-color: none !important;
  }

  span .option-text-active{
    font-weight: 800;
    font-family: $primary-font;
    font-optical-sizing: auto;
    color: #001C44;
  }
/* 
  .placeholder-active::placeholder{
    color: #001C44;
  } */

  .option-text {
    font-weight: 800;
    font-family: $primary-font;
    font-optical-sizing: auto;
    color: #001C44;
  }
}


/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: hsl(269, 27%, 33%);
  }


  :root{
    font-family: $primary-font !important;
    font-optical-sizing: auto !important;
  }

  ngx-json-viewer {
    --ngx-json-font-size: .8rem;
  }
  